import CPNde from "locales/de/commonPricesNew.json";
import CPNen from "locales/en/commonPricesNew.json";
import CPNenUS from "locales/en-US/commonPricesNew.json";
import CPNes from "locales/es/commonPricesNew.json";
import CPNfr from "locales/fr/commonPricesNew.json";
import CPNit from "locales/it/commonPricesNew.json";
import CPNpt from "locales/pt/commonPricesNew.json";
import CPNuk from "locales/uk/commonPricesNew.json";
import { CountryVersion, Language } from "constants/enum";

export default (language: Language, version: CountryVersion) => {
    if (language === Language.ENGLISH && version === CountryVersion.US) {
        return CPNenUS;
    }

    const allContent = {
        [Language.GERMANY]: CPNde,
        [Language.ENGLISH]: CPNen,
        [Language.SPAIN]: CPNes,
        [Language.FRANCE]: CPNfr,
        [Language.ITALY]: CPNit,
        [Language.PORTUGAL]: CPNpt,
        [Language.UKRAINE]: CPNuk,
    };

    if (language in allContent) {
        // @ts-ignore
        return allContent[language];
    }

    return {};
};
