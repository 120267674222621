import { Translate } from "next-translate";

import { pathConfig } from "configs/pathConfig";

import { PageContentProps } from "./content.types";

const pageContent = (t: Translate, { version }: PageContentProps) => {
    return {
        title: t("title"),
        description: t("description"),
        cta: {
            name: t("cta"),
            link: pathConfig.universalForm[version],
        },
    };
};

export default pageContent;
