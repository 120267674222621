import { FC } from "react";
import {
    Heading,
    Text,
    Container,
    Button,
    Flex,
    Link,
} from "@smile2impress/components";
import useTranslation from "next-translate/useTranslation";
import {
    CountryVersion as newCV,
    Language as newLang,
} from "@smile2impress/attraction-constants";
import { PriceBlock as ACPB } from "@smile2impress/price-block";
import { mergeDeepRight } from "ramda";

import prices from "configs/prices.json";
import { useLocationContext } from "context/locationContext";
import { Language } from "constants/enum";
import { sanitizeHtml } from "utils/sanitizeHtml";

import pageContent from "./content/common";
import { PriceBlockProps, PriceBlockContent } from "./PriceBlock.types";
import getLocaleFile from "./locales";

export const PriceBlock: FC<PriceBlockProps> = ({
    cases,
    advantages,
    onCTAClick,
    prefix,
    contentOverride = {},
}) => {
    const { t } = useTranslation("commonPricesNew");
    const { version, language = Language.SPAIN } = useLocationContext();

    const { title, description, cta } = mergeDeepRight(
        pageContent(t, {
            version,
        }),
        contentOverride as PriceBlockContent
    ) as PriceBlockContent;

    return (
        <Flex as="section" flexDir={["column", "column", "row"]}>
            <Container
                variant="base"
                w={["auto", "auto", "50%"]}
                pr={[3, 3, 10]}
            >
                <Heading
                    textStyle="title1"
                    mb={[3, 4]}
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(title),
                    }}
                />
                <Text textStyle="body" mb={5} color="text.secondary">
                    {description}
                </Text>
                {cta?.link && (
                    <Button
                        display={["none", "none", "flex"]}
                        minW="240px"
                        w="fit-content"
                        {...(onCTAClick
                            ? { onClick: onCTAClick }
                            : { href: cta.link, as: Link })}
                    >
                        {cta.name}
                    </Button>
                )}
            </Container>
            <Flex flexDir="column" w={["auto", "auto", "50%"]}>
                <ACPB
                    prefix={prefix}
                    // @ts-ignore
                    language={language as newLang}
                    // @ts-ignore
                    version={version as newCV}
                    localeFile={getLocaleFile(language, version)}
                    // @ts-ignore @TODO urgent fix
                    prices={prices}
                    advantages={advantages}
                    cases={cases}
                    disclaimerAlwaysVisible={(version as newCV) === newCV.ITALY}
                />
            </Flex>
        </Flex>
    );
};
